import {
  trainingState,
  uiState
} from '@/stores'
import {
  trainingManager,
  modes,
  fpsManager,
  gsap
} from '@powerplay/core-minigames'
import { Tasks } from '../../types'
import { trainingConfig } from '@/app/config'
import { player } from '@/app/entities/player'

/**
 * Trieda pre treningove ulohy
 */
export class TrainingTasks {

  /** Mnozstvo taskov */
  private NUMBER_OF_TASKS = 4

  /** Pocet frameov v zavetri */
  private framesInSlipstream = 0

  /** Pocet prejdenych checkpointov */
  private checkPointsPassed = 0

  /** Pozicia v cieli */
  public position = 0

  /** tween na schovanie slip stream ui */
  public hideSlipStreamWarningTween?: gsap.core.Tween

  /**
   * Inicializovanie treningu
   */
  public initTraining(): void {

    trainingManager.setNumberOfTasks(this.NUMBER_OF_TASKS)

  }

  /**
   * Hlavna metoda ktoru volame pri pouziti trainingovych Taskov
   * @param task - Typu Tasks, typ ulohy ktorej hodnoty sa zadavaju
   * @param valueToCount - hodnota ktora sa zadava
   */
  public countTaskValue(task: Tasks, valueToCount: number): void {

    if (!modes.isTrainingMode()) return

    if (task === Tasks.checkpoints) this.countPassedCheckpoints(valueToCount)
    if (task === Tasks.slipstream) this.countSlipstream(valueToCount)
    if (task === Tasks.finish) this.countFinish(valueToCount)

  }

  /**
   * Vratenie poctu percent za checkpointy
   * @returns
   */
  private getCheckpointsPassedPercent(): number {

    return this.checkPointsPassed / trainingConfig.checkpointPositions.length

  }

  /**
   * Metoda na vyhodnotenie checkpointov
   * @param valueToCount - Hodnota checkpointov
   */
  private countPassedCheckpoints(valueToCount: number): void {

    this.checkPointsPassed = valueToCount

    this.changeUI(Tasks.checkpoints, this.getCheckpointsPassedPercent())

  }

  /**
   * Vratenie poctu percent za jazdu v zavetri
   * @returns Percenta
   */
  private getSlipstreamPercent(): number {

    let percent = this.framesInSlipstream / trainingConfig.maxFramesSlipstream
    if (percent > 1) percent = 1

    return percent

  }

  /**
   * Metoda na vyhodnotenie zavetria
   * @param valueToCount - Hodnota pridavku
   */
  private countSlipstream(valueToCount: number): void {

    this.framesInSlipstream += valueToCount
    const seconds = Math.round(this.framesInSlipstream / fpsManager.fpsLimit * 10) / 10
    this.changeUI(Tasks.slipstream, this.getSlipstreamPercent())

    if (seconds > 10) {

      if (this.hideSlipStreamWarningTween === undefined) {

        this.hideSlipStreamWarningTween = gsap.to({}, {
          onComplete: () => {

            uiState().hideSlipStreamWarning = true

          },
          duration: 1
        })

      }

    } else {

      uiState().slipStreamSeconds = seconds.toFixed(1)

    }

  }

  /**
   * Metoda na vyhodnotenie zavetria
   * @param valueToCount - Hodnota pridavku
   */
  private countFinish(valueToCount: number): void {

    if (player.isEnd) return
    this.position += valueToCount

  }

  /**
   * Hlavna privatna metoda, ktora riesi UI zmeny a trainingManager classu
   * @param name - meno tasku
   * @param value - Vyhodnotena hodnota tasku
   */
  public saveTaskValue(name: Tasks, value: number): void {

    if (!modes.isTrainingMode()) return

    value = Math.ceil(value * 10000) / 10000

    if (name === Tasks.finish) {

      console.log(`Training tasks value ${value}, position: ${this.position}`)

    }

    this.changeUI(name, value)
    trainingManager.setTaskValue(name, value)

  }

  /**
   * Zmena UI
   * @param name - meno tasku
   * @param value - Vyhodnotena hodnota tasku
   */
  private changeUI(name: Tasks, value: number) {

    if (!modes.isTrainingMode()) return
    // console.log(`UI zmeny ${name} ma hodnotu ${value}`)
    const percent = Math.ceil(value * 100)
    const tpTask = Math.ceil(Math.round((trainingManager.getTpPerTask() * value * 10) + Number.EPSILON) / 10)
    console.log('tp task:', tpTask)

    if (tpTask !== undefined) {

      trainingState().editTask({
        text: `trainingTask8-${name}`,
        percent: String(percent),
        points: String(tpTask),
        showPoints: true
      })

    }

  }

  /**
   * Ulozenie poslednych uloh
   */
  public saveLastTasksValues(): void {

    if (!modes.isTrainingMode()) return

    this.saveTaskValue(Tasks.slipstream, this.getSlipstreamPercent())
    this.saveTaskValue(Tasks.checkpoints, this.getCheckpointsPassedPercent())

    console.log('FINAL DATA SENT')

  }

  /**
   * Reset
   */
  public reset(): void {

    trainingManager.resetAll()
    this.framesInSlipstream = 0
    this.position = 0
    this.checkPointsPassed = 0
    uiState().slipStreamSeconds = '0.0'
    this.hideSlipStreamWarningTween = undefined

  }

}

export const trainingTasks = new TrainingTasks()
