import { Sides } from '@/app/types'
import { defineStore } from 'pinia'

export interface BlockingState {
  side: Sides | undefined,
  isVisible: boolean
}

const initialState = (): BlockingState => ({
  side: Sides.LEFT,
  isVisible: false
})

export const blockingState = defineStore('blockingState', {
  state: initialState
})
