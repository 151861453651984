import {
  actionButtonState,
  gamePhaseState,
  inputsState,
  movementButtonsState,
  movementState
} from '@/stores'
import {
  Sides,
  type DisciplinePhaseManager,
  AudioNames,
  AudioGroups
} from '../../types'
import {
  MobileDetector,
  audioManager
} from '@powerplay/core-minigames'
import { player } from '@/app/entities/player'
import { audioHelper } from '@/app/audioHelper/AudioHelper'
import { inputsManager } from '@/app/InputsManager'

/**
 * Trieda fazy pre zjazd
 */
export class RunningPhase implements DisciplinePhaseManager {

  /** blokovanie zmeny drahy */
  public switchLaneBlocker = false

  /** Pocitadlo frameov */
  private framesCounter = 0

  /** callback na zavolanie po skonceni fazy */
  private callbackEnd: () => unknown

  /**
   * Konstruktor
   */
  public constructor(callbackEnd: () => unknown) {

    this.callbackEnd = callbackEnd

  }

  /**
   * Pripravenie fazy
   */
  public preparePhase = (): void => {

    // zatial netreba nic

  }

  /**
   * Start fazy
   */
  public startPhase = (): void => {

    console.warn('game phase started')

  }

  /**
   * Zobrazenie bezeckeho UI (pri rozbehu este nebude)
   */
  public showRunningUi(): void {

    gamePhaseState().showBar = true
    movementButtonsState().isActive = true
    this.showMobileButtons()

  }

  /**
   * Zobrazenie mobilnych buttonov
   */
  public showMobileButtons(): void {

    if (!MobileDetector.isMobile()) return

    actionButtonState().showJoystick = true
    inputsState().disabled = false

  }

  /**
   * Aktualizovanie fazy
   */
  public update = (): void => {

    this.framesCounter += 1

    if (this.framesCounter === 15) {

      audioManager.stopAudioByGroup(AudioGroups.commentators)
      audioManager.play(AudioNames.commentAfterStart)

    }

    audioHelper.update()

    if (
      (inputsManager.moveDirectionLeft || movementState().positionX < 0) &&
            !this.switchLaneBlocker
    ) {

      this.switchLaneBlocker = true
      player.changePath(Sides.LEFT)

    } else if (
      (inputsManager.moveDirectionRight ||
            movementState().positionX > 0) &&
            !this.switchLaneBlocker
    ) {

      this.switchLaneBlocker = true
      player.changePath(Sides.RIGHT)

    }
    /*
     * if (
     *     !inputsManager.moveDirectionRight &&
     *     !inputsManager.moveDirectionLeft &&
     *     movementState().positionX === 0
     * ) {
     */

    // }

  }

  /**
   * Ukoncene fazy
   * @param type - Typ ukoncenia
   */
  public finishPhase = (): void => {

    console.warn('game phase ended')
    this.framesCounter = 0
    this.callbackEnd()

  }

  /**
   * sets finish phase tween
   */
  public setFinishPhaseTween(): void {

    //

  }

}
