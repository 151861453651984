import { SmallActionButtonColors } from '@/app/types'
import { defineStore } from 'pinia'

export interface GamePhaseState {
  showBar: boolean,
  speedPower: number,
  sprintBarState: number,
  showSmallActionButtons: boolean,
  disabledSmallAction: boolean,
  showUi: boolean,
  isAllowedToTuck: boolean,
  isAllowedToSprint: boolean,
  sprintButtonColor: SmallActionButtonColors,
  downhillButtonColor: SmallActionButtonColors,
  maxSpeed: number
}

const initialState = (): GamePhaseState => ({
  showBar: false,
  speedPower: 1,
  sprintBarState: 0,
  showSmallActionButtons: true,
  disabledSmallAction: false,
  showUi: true,
  isAllowedToTuck: true,
  isAllowedToSprint: true,
  sprintButtonColor: SmallActionButtonColors.yellow,
  downhillButtonColor: SmallActionButtonColors.yellow,
  maxSpeed: 100
})

export const gamePhaseState = defineStore('gamePhaseState', {
  state: initialState
})
