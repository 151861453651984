/** Interface pre manazer faz v discipline */
export interface DisciplinePhaseManager {

  preparePhase(): void;
  startPhase(): void;
  finishPhase(): void;
  update(): void;
  setFinishPhaseTween(): void;

}

/** Disciplinove fazy */
export enum DisciplinePhases {

  preStart = 0,
  start = 1,
  running = 2,
  finish = 3,
  end = 4

}

/** Stavy pohybu v cieli */
export enum MovementInFinishPhases {

  before = 0,
  setThings = 1,
  nonLinearDecrease = 2,
  linearDecrease = 3,
  stay = 4

}
