import type { StartPositionsDataObject } from '@/app/types'
import {
  minigameConfig,
  modes,
  playersManager,
  type PlayerInfo,
  type TournamentDataFromResultsRequest
} from '@powerplay/core-minigames'
import { Opponent } from './Opponent'
import {
  dailyLeagueOpponentsAttributeStrengths,
  fakeOpponents,
  trainingOpponentsAttributeStrengths
} from '@/app/modes/training'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import { player } from '../player'
import { gameConfig } from '@/app/config'

/** Trieda pre spravu protihracov */
export class OpponentsManager {

  /** Pole superov */
  private opponents: Opponent[] = []

  /** pocitadlo framov */
  private frameCounter = 0

  /** true ak v danom mode su opponenti */
  private isActive = true

  /** originalne data hracov */
  private originalPlayersData: PlayerInfo[] = []

  /**
   * Prepisanie dat superov
   */
  public rewriteOpponentsData(): void {

    if (!modes.isTutorial() && !modes.isTrainingMode() && !modes.isDailyLeague() && !modes.isTournament()) return

    this.setFakeOpponentsData()

  }

  /**
   * Vytvorenie superov
   */
  public create(startData: StartPositionsDataObject): void {

    /*
     * nastavime si, kedy su superi neaktivni
     * if (modes.isDailyLeague()) {
     */

    //     this.isActive = false

    // }

    if (!this.isActive) return

    const opponents = playersManager.players.filter((playerInfo: PlayerInfo, index: number) => {

      return !playerInfo.playable && index <= 7

    })

    // v tutoriali si musime nafakeovat data
    if (modes.isTutorial()) {

      const startDataTutorial = [
        { row: 1,
          pathIndex: 0 },
        { row: 1,
          pathIndex: 2 },
        { row: 1,
          pathIndex: 6 }
      ]
      for (let i = 0; i < 3; i += 1) {

        const playerInfo = opponents[i]
        this.opponents[i] = new Opponent(playerInfo.uuid, i + 1)
        this.opponents[i].create(startDataTutorial[i])

      }
      return

    }
    if (modes.isEventBossFight()) {

      const bossOpponent = opponents[0]
      const { dataForPlayersStartPositions } = disciplinePhasesManager
      const playerPathIndex = dataForPlayersStartPositions[player.uuid].pathIndex
      dataForPlayersStartPositions[bossOpponent.uuid] = {
        row: 1,
        pathIndex: playerPathIndex === 0 ? 2 : 0
      }
      this.opponents[0] = new Opponent(bossOpponent.uuid, 1)
      this.opponents[0].create(dataForPlayersStartPositions[bossOpponent.uuid])
      return

    }

    opponents.forEach((playerInfo: PlayerInfo, index: number) => {

      this.opponents[index] = new Opponent(playerInfo.uuid, index + 1)
      if (modes.isTrainingMode()) {

        this.opponents[index].setAttributeStrength(trainingOpponentsAttributeStrengths[index])

      }
      if (modes.isDailyLeague() || modes.isTournament()) {

        this.opponents[index].setAttributeStrength(dailyLeagueOpponentsAttributeStrengths[index])

      }
      this.opponents[index].create(startData[playerInfo.uuid])

    })

  }

  /**
   * Nastavenie fake dat pre tutorial a trening pre superov
   */
  private setFakeOpponentsData(): void {

    if (modes.isDailyLeague() || modes.isTournament()) {

      for (let i = 0; i <= playersManager.players.length - 1; i += 1) {

        this.originalPlayersData[i] = playersManager.players[i]

      }

    }

    this.setFakeData()

  }

  /**
   * Vratenie fake dat hracov
   */
  public setFakeData(): void {

    for (let i = 1; i <= gameConfig.numberOfOpponents; i += 1) {

      playersManager.players[i] = fakeOpponents[i - 1]

    }

  }

  /**
   * Vratenie originalnych dat realnych hracov
   */
  public setOriginalData(): void {

    // pozor, tu menime aj hraca, nielen superov
    playersManager.players = []

    for (let i = 0; i <= this.originalPlayersData.length - 1; i += 1) {

      playersManager.players[i] = this.originalPlayersData[i]

      const resultArr = playersManager.players[i].resultsArr
      if (resultArr && resultArr[0] && resultArr[0].main === -1) {

        resultArr[0].main = minigameConfig.dnsValueAscending

      }

    }

  }

  /**
   * aktualizovanie superov pred fyzikou
   */
  public updateBeforePhysics(): void {

    if (!this.isActive) return

    this.frameCounter += 1

    this.opponents.forEach((opponent) => {

      opponent.updateBeforePhysics(this.frameCounter)

    })

  }

  /**
   * aktualizovanie superov po fyzikou
   */
  public updateAfterPhysics(): void {

    if (!this.isActive) return

    this.opponents.forEach((opponent) => {

      opponent.updateAfterPhysics()

    })

  }

  /**
   * Odstartovanie vsetkych superov
   */
  public start(): void {

    if (!this.isActive) return

    this.frameCounter = 0

    this.opponents.forEach((opponent) => {

      opponent.setDelayedStartFrames()

    })



  }

  /**
   * Aktualizovanie animacii superov
   * @param delta - delta pre animacie
   */
  public updateAnimations(delta: number): void {

    if (!this.isActive) return

    this.opponents.forEach((opponent) => {

      opponent.updateAnimations(delta)

    })

  }

  /**
   * Getter
   * @returns opponents
   */
  public getOpponents(): Opponent[] {

    return this.opponents

  }

  /**
   * Getter
   * @returns uuids
   */
  public getOpponentsIds(): string[] {

    const uuids = [] as string[]
    playersManager.players.filter((playerInfo: PlayerInfo, index: number) => {

      return !playerInfo.playable && index <= 7

    }).forEach((playerInfo: PlayerInfo) => {

      uuids.push(playerInfo.uuid)

    })
    return uuids

  }

  /**
   * Nastavenie dat o hracoch
   * @param dataFromCallback - data o hracoch
   */
  public setOpponentsDataFromSaveResults(dataFromCallback: TournamentDataFromResultsRequest): void {

    const newPlayersData: PlayerInfo[] = []
    let index = 0
    dataFromCallback.players.forEach((playerInfo) => {

      playerInfo.attribute = {
        base: 0,
        total: 0
      }

      if (playerInfo.uuid === player.uuid) playerInfo.playable = true
      if (playerInfo.resultsArr && playerInfo.resultsArr[0].main === -1) {

        playerInfo.resultsArr[0].main = minigameConfig.dnsValueAscending

      }

      // musime zarucit, ze hrac bude aj tu prvy
      if (!playerInfo.playable) index += 1
      newPlayersData[playerInfo.playable ? 0 : index] = playerInfo

    })

    console.log('vysledky pred', JSON.parse(JSON.stringify(playersManager.players)))
    playersManager.players = newPlayersData
    console.log('vysledky po', JSON.parse(JSON.stringify(playersManager.players)))

    // musime este upravit data pre hraca, kedze sa vyssim priradenim dali na DNF
    playersManager.players[0].resultsArr = playersManager.players[0].resultsArrOriginal

  }

  /**
   * Resetovanie veci
   * @param startData - data pre pozicie opponentov
   */
  public reset(startData: StartPositionsDataObject): void {

    this.frameCounter = 0

    this.opponents.forEach((opponent) => {

      opponent.reset(startData[opponent.uuid])

    })

  }

}

export const opponentsManager = new OpponentsManager()
