import { defineStore } from 'pinia'

export interface InstructionsState {
  showInstructions: boolean,
  showButton: boolean
}

const initialState = (): InstructionsState => ({
  showInstructions: false,
  showButton: true
})

export const instructionsState = defineStore('instructionsState', {
  state: initialState
})
