import { gameConfig } from '@/app/config'
import {
  AudioNames,
  SmallActionButtonColors
} from '@/app/types'
import { gamePhaseState } from '@/stores'
import { player } from '.'
import { audioManager } from '@powerplay/core-minigames'

/**
 * Trieda pre spravu sprintu hraca
 */
export class PlayerSprintBarManager {

  /** Mnozstvo sprintu v sprintbare */
  private barValue = 0

  /** Mnozstvo frameov bez sprint fillu */
  private frameWithoutFill = 0

  /** Minimalna hodnota baru */
  public readonly MIN_VALUE = 0

  /** Maximalna hodnota baru */
  public readonly MAX_VALUE = 100

  /**
   * Obraz stateu storageu ktorym zabezpecime, ze nebudeme permanentne posielat zmenu stavou
   * do storage - farba obrazka
   */
  private actualColor = SmallActionButtonColors.yellow

  /** Zistenie ci sa smie sprintovat */
  public isSprintAvailable(): boolean {

    let isAvailable = this.barValue >= gameConfig.turnOnSprintDecrease
    if (player.inSafeZoneOfAthlete !== undefined) isAvailable = false

    return isAvailable

  }

  /**
   * Getter
   * @returns barValue
   */
  public getBarValue(): number {

    return this.barValue

  }

  /**
   * Nastavenie farby pre button
   * @param color - Farba
   */
  private setButtonColor(color: SmallActionButtonColors): void {

    if (this.actualColor === color) return

    this.actualColor = color

    // zmenime farbu
    gamePhaseState().sprintButtonColor = color

  }

  /**
   * Metoda na upravu sprintBaru hore
   * @param speedBarValue - Hodnota speed baru
   */
  private refillSprint(speedBarValue: number): void {

    if (
      this.frameWithoutFill >= gameConfig.sprintBarFrameRate
    ) {

      this.setButtonColor(SmallActionButtonColors.yellow)

      let sprintBarIncrease = (player.getSpeedBarMaxValue() - speedBarValue) / 100 +
                gameConfig.sprintBarFillRate
      if (player.isTuck) {

        sprintBarIncrease = gameConfig.sprintBarDownhillIncreaseRate

      } else if (player.isInSlipStream) {

        sprintBarIncrease = gameConfig.sprintBarSlipstreamIncreaseRate

      }

      this.barValue += sprintBarIncrease
      this.frameWithoutFill = 0

      if (this.barValue > this.MAX_VALUE) this.barValue = this.MAX_VALUE

    } else {

      this.setButtonColor(SmallActionButtonColors.yellow)
      this.frameWithoutFill++

    }

  }

  /**
   * Komplexna metoda ktora riesi sprint bar ako aj povolenie sprintovat
   * @returns void
   */
  private handleSprint(): void {

    if (this.isSprintAvailable()) {

      const {
        sprintBarDecreaseRate, sprintBarDecreaseRateSlipstream, sprintBarFrameRate
      } = gameConfig

      if (this.frameWithoutFill >= sprintBarFrameRate) {

        this.setButtonColor(SmallActionButtonColors.green)

        let sprintBarDecreaseRateValue = sprintBarDecreaseRate
        if (player.isInSlipStream) {

          sprintBarDecreaseRateValue = sprintBarDecreaseRateSlipstream

        }
        this.barValue -= sprintBarDecreaseRateValue
        this.frameWithoutFill = 0

        if (this.barValue < this.MIN_VALUE) {

          this.barValue = this.MIN_VALUE

        }

      } else {

        this.frameWithoutFill++

      }

    } else {

      this.setSprinting(false)

    }

  }

  /**
   * Aktualizacia sprint baru
   * @param speedBarValue - Hodnota speed baru
   */
  public update(speedBarValue: number): void {

    if (player.isSprinting) {

      this.handleSprint()

    } else {

      this.refillSprint(speedBarValue)

    }

    gamePhaseState().sprintBarState = this.barValue

  }

  /**
   * Zapnutie/vypnutie sprintu
   * @param value - value to set
   */
  public setSprinting(value = !player.isSprinting): void {

    if (!player.isSprinting) {

      this.barValue -= gameConfig.turnOnSprintDecrease

      if (this.barValue < this.MIN_VALUE) {

        this.barValue = this.MIN_VALUE

      }

    }
    if (player.isSprinting && !value && player.inSafeZoneOfAthlete) {

      player.showBlockingEffect(undefined)

    }
    player.isSprinting = value

    // poriesime zvuky
    if (value) {

      audioManager.play(AudioNames.heavyBreathing)

    } else {

      audioManager.stopAudioByName(AudioNames.heavyBreathing)

    }

  }

  /**
   * Nastavenie hodnoty pre bar
   * @param value - hodnota
   * @returns PlayerSprintBarManager
   */
  public setBarValue(value: number): this {

    this.barValue = value
    return this

  }

  /**
   * Resetovanie veci
   */
  public reset(): void {

    this.barValue = 0
    this.frameWithoutFill = 0

  }

}
