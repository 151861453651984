import { defineStore } from 'pinia'

export interface TuckState {
  isTuck: boolean
}

const initialState = (): TuckState => ({
  isTuck: false
})

export const tuckState = defineStore('tuckState', {
  state: initialState
})

