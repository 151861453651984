/** Cofig na snezne partikle */
export const snowParticleConfig = [
  {
    active: false,
    particleAmountPerEmit: 0
  },
  {
    active: true,
    particleAmountPerEmit: 10
  },
  {
    active: true,
    particleAmountPerEmit: 50
  },
  {
    active: true,
    particleAmountPerEmit: 100
  },
  {
    active: true,
    particleAmountPerEmit: 150
  }
]

/** Skalar na posun partiklov pocas hry */
export const gameMultiplicationScalar = 10
