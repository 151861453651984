/**
 * typ pri zobrazovani hlasok hracovi
 */
export interface DisplayMessage {
  message: string,
  color: number
}

/**
 * data zobrazovanie pocas hry
 */
export interface GameplayTableRow {

  position: number,
  country: string,
  countryString: string,
  player: {
    name: string,
    isPlayer: boolean
  },
  time: string,
  timeDiff: string,
  isBonus: boolean

}

/**
 * cas zobrazovany pocas hry
 */
export interface TimeData {

  time: string,
  timeDiff: string

}

/**
 * farby malych action buttonov
 */
export enum SmallActionButtonColors {

  green = 'green',
  yellow = 'yellow',
  red = 'red'

}
