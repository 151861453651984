<template>
  <time-keeper
    v-if="uiState.showTimeKeeper"
    class="time-keeper"
    :text1="timeState.timeWithPenalty"
    :text2="`+ ${timeState.penaltySeconds}s`"
    :show-text2="isPenalty"
    :is-scaled="false"
  />
</template>

<script lang="ts">
import { TimeKeeper } from '@powerplay/core-minigames-ui'

import { defineComponent } from 'vue'
import {
  timeState,
  uiState
} from '@/stores'

export default defineComponent({
  name: 'TimeKeeperComponent',
  components: {
    TimeKeeper
  },
  data() {

    return {
      timeState: timeState(),
      uiState: uiState()
    }

  },
  computed: {
    isPenalty(): boolean {

      return this.timeState.penaltySeconds > 0

    }
  }

})

</script>

<style lang="less">
.component-item {

  .time-keeper {
    position: initial;
    height: auto;

    .time-keeper-penalty-wrapper {
      width: 228px;
    }
  }
}
</style>
