<template>
  <section
    class="positioner"
    style="height:100%"
  >
    <section v-if="inputsState.isVisible && gamePhaseState.showUi">
      <section
        v-if="!gameSettingsState.isLeft"
        class="flex justify-between"
      >
        <movement-control
          v-show="showSideArrows"
          :style="[
            {transform: `scale(${scaleCoef})`, width: '100%'},
            movementControlPosition
          ]"
          :disabled="inputsState.disabled"
          class="bottom-left pointer-events-auto"
        />
        <section v-show="!showSideArrows" />
        <section
          :style="{position: 'absolute', right: '0', bottom: '0'}"
        >
          <div
            class="bottom-right"
            :style="{transform: `scale(${scaleCoef})`}"
          >
            <action-button
              v-if="!movementButtonsState.isActive && actionButtonState.isStart"
              class="pointer-events-auto"
            />
            <movement-buttons-component
              v-if="movementButtonsState.isActive"
              :disabled="inputsState.disabled"
              class="pointer-events-auto"
            />
            <small-action-buttons
              v-if="
                gamePhaseState.showBar && gamePhaseState.showSmallActionButtons
              "
              :disabled="inputsState.disabled"
              is-mobile-forced
              class="buttons-position pointer-events-auto"
            />
            <start-efficiency-bar
              v-if="startPhaseState.showBar"
              :is-scaled="false"
              is-mobile
            />
            <speed-bar
              v-if="
                gamePhaseState.showBar && gamePhaseState.showSmallActionButtons
              "
              :is-scaled="false"
              is-mobile
            />
          </div>
        </section>
      </section>
      <section
        v-else
        class="flex justify-between"
      >
        <section
          :style="{position: 'absolute', left: '0', bottom: '0'}"
        >
          <div
            class="bottom-left"
            :style="{transform: `scale(${scaleCoef})`}"
          >
            <action-button
              v-if="!movementButtonsState.isActive && actionButtonState.isStart"
              class="pointer-events-auto"
            />
            <movement-buttons-component
              v-if="movementButtonsState.isActive"
              :disabled="inputsState.disabled"
            />
            <small-action-buttons
              v-if="
                gamePhaseState.showBar && gamePhaseState.showSmallActionButtons
              "
              :disabled="inputsState.disabled"
              is-mobile-forced
              class="buttons-position pointer-events-auto"
            />
            <start-efficiency-bar
              v-if="startPhaseState.showBar"
              :is-left="gameSettingsState.isLeft"
              :is-scaled="false"
              is-mobile
            />
            <speed-bar
              v-if="
                gamePhaseState.showBar && gamePhaseState.showSmallActionButtons
              "
              is-mobile
              :is-scaled="false"
              :is-left="gameSettingsState.isLeft"
            />
          </div>
        </section>
        <movement-control
          v-if="showSideArrows"
          :style="[
            {transform: `scale(${scaleCoef})`, width: '100%'},
            movementControlPosition
          ]"
          class="bottom-right pointer-events-auto"
          :disabled="inputsState.disabled"
        />
      </section>
    </section>
  </section>
</template>

<script lang="ts">
import MovementControl from './MovementControl.vue'
import ActionButton from './ActionButton.vue'
import StartEfficiencyBar from './StartEfficiencyBar.vue'
import SmallActionButtons from './SmallActionButtons.vue'
import SpeedBar from './SpeedBar.vue'
import MovementButtonsComponent from './MovementButtonsComponent.vue'
import {
  gameSettingsState,
  WindowAspect
} from '@powerplay/core-minigames-ui'
import { defineComponent } from 'vue'
import { modes } from '@powerplay/core-minigames'
import {
  gamePhaseState,
  inputsState,
  movementButtonsState,
  startPhaseState,
  tutorialState
} from '@/stores'
import { actionButtonState } from '@/stores/actionButtonState'

export default defineComponent({
  name: 'MobileInputs',
  components: {
    MovementControl,
    StartEfficiencyBar,
    ActionButton,
    SmallActionButtons,
    SpeedBar,
    MovementButtonsComponent
  },
  mixins: [WindowAspect],
  data() {

    return {
      inputsState: inputsState(),
      tutorialState: tutorialState(),
      startPhaseState: startPhaseState(),
      gamePhaseState: gamePhaseState(),
      movementButtonsState: movementButtonsState(),
      actionButtonState: actionButtonState(),
      gameSettingsState: gameSettingsState(),
    }

  },
  computed: {
    movementControlPosition() {

      return {
        position: 'absolute',
        height: '100%'
      }

    },
    showSideArrows() {

      if (modes.isTutorial()) {

        return actionButtonState().showJoystick && tutorialState().showSideControls

      }
      return actionButtonState().showJoystick

    }
  }
})
</script>

<style lang="less" scoped>
  .positioner {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  .flex {
    display: flex;
  }

  .justify-between {
    justify-content: space-between;
  }

  .relative {
    position: relative;
  }

  .pointer-events-auto {
    pointer-events: auto;
  }

  .buttons-position {
    position: absolute;
    top: -60px;
    left: 0;
    width: 100%;
    height: 200px;
  }
  .bottom-right {
    transform-origin: bottom right;
    display: flex;
    justify-content: flex-end;
  }
  .bottom-left {
    transform-origin: bottom left;
    display: flex;
    justify-content: flex-start;
  }

</style>
