<template>
  <mobile-button
    :type="type"
    :is-scaled="false"
    :disabled="actionButtonState.disabled"
    @mousedown="performAction"
    @mouseup="endAction"
    @touchstart="performAction"
    @touchend="endAction"
  />
</template>

<script lang="ts">
import { MobileButton } from '@powerplay/core-minigames-ui'
import { inputsManager } from '@powerplay/core-minigames'
import { player } from '@/app/entities/player'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import type { StartPhaseManager } from '@/app/phases/StartPhase/StartPhase'
import { DisciplinePhases } from '@/app/types'

import { defineComponent } from 'vue'
import { actionButtonState } from '@/stores'
import type { PlayerVelocityManager } from '@/app/entities/player/PlayerVelocityManager'

export default defineComponent({
  components: {
    MobileButton
  },
  data() {

    return {
      actionButtonState: actionButtonState()
    }

  },
  computed: {
    type() {

      if (actionButtonState().isStart) return 'start-cross-country'
      return ''

    }
  },
  methods: {
    performAction() {

      if (actionButtonState().disabled) return

      if (this.type === 'start-cross-country') {

        actionButtonState().touchStart = true
        const startPhase = disciplinePhasesManager
          .getDisciplinePhaseManager(DisciplinePhases.start) as StartPhaseManager
        startPhase.handleInputs()
        const playerVelocityManager = player.velocityManager as PlayerVelocityManager
        playerVelocityManager.handleStartRunInputs()

      }

    },
    endAction() {

      // musime dat manulane ukoncenie buttonu, aby sa ukoncil takisto actionPressed v inputs
      inputsManager.handleMouseUp()

    }
  }
})

</script>

<style>

</style>
