<template>
  <div>
    <power-stick
      v-if="showJoystick()"
      :disabled="disabled"
      :only-horizontal="movementState.onlyHorizontal"
      is-scaled="false"
      @position-x="changeMovementX"
      @positiom-y="changeMovementY"
      @end-contact="endContact"
    />
    <movement-buttons
      v-if="showArrows()"
      :is-scaled="false"
      :disabled="disabled"
      :style="{
        position: 'absolute',
        bottom: '0%'
      }"
      @position="changeMovementX"
      @end-contact="endContact"
    />
  </div>
</template>

<script lang="ts">

import {
  MovementButtons,
  PowerStick
} from '@powerplay/core-minigames-ui'
import { inputsManager } from '@powerplay/core-minigames'

import { defineComponent } from 'vue'
import {
  inputsState,
  movementState
} from '@/stores'

export default defineComponent({
  components: {
    PowerStick,
    MovementButtons
  },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {

    return {
      movementState: movementState()
    }

  },
  methods: {
    endContact() {

      // musime dat manulane ukoncenie buttonu, aby sa ukoncil takisto actionPressed v inputs
      inputsManager.handleMouseUp()
      movementState().$patch({
        positionX: 0,
        positionY: 0
      })

    },
    changeMovementX(positionX: number) {

      if (this.disabled) return

      movementState().positionX = positionX

    },
    changeMovementY(positionY: number) {

      if (this.disabled) return

      movementState().positionY = positionY

    },
    showArrows() {

      return !this.showJoystick()

    },
    showJoystick() {

      return inputsState().isForceJoystick

    }

  }
})

</script>
