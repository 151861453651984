import { velocityConfig } from '@/app/config'
import { SmallActionButtonColors } from '@/app/types'
import { gamePhaseState } from '@/stores'
import { playersManager } from '@powerplay/core-minigames'
import type { Athlete } from './Athlete'

/**
 * Downhill stav manager
 */
export class DownhillManager {

  /** Samotne povolenie ci sa smie spustat wonhill */
  private isDownhillAllowed = false

  /** Obraz stateu storageu, takze nebudeme permanentne posielat zmenu stavou do storage. */
  private localStateImage = true

  /** Aktuana farba buttonu */
  private actualColor = SmallActionButtonColors.yellow

  /**
   * Konstruktor
   * @param athlete - Atlet
   */
  public constructor(private athlete: Athlete) {}

  /** manualne povolenie downhillu */
  public allowDownhill(): void {

    this.isDownhillAllowed = true

  }

  /**
   * Public metoday na zistenie ci je downhill
   * @returns Ci je downhill povoleny
   */
  public getDownhillAllowed(): boolean {

    return this.isDownhillAllowed

  }

  /**
   * Zistenie ci sa ma udiat akcia zmeny povolenia downhillu
   */
  private checkIfActionMustHappen(): void {

    const { downhillGradientLimit } = velocityConfig
    this.isDownhillAllowed = this.athlete.getVelocityGradient() < downhillGradientLimit

  }

  /**
   * Metoda na ovladanie UI stavou
   */
  private makeOperationsInUiState(): void {

    // iba pre hraca za ktoreho hrame
    if (!playersManager.getPlayerById(this.athlete.uuid)?.playable) return

    this.decideButtonColor()

    if (this.isDownhillAllowed) {

      if (!this.localStateImage) {

        // nechat downhill button povoleny
        gamePhaseState().isAllowedToTuck = true
        this.localStateImage = true

      }

    } else {

      if (this.athlete.isTuck) {

        if (!this.localStateImage) {

          // nechat downhill button povoleny
          gamePhaseState().isAllowedToTuck = true
          this.localStateImage = true

        }

      } else {

        if (this.localStateImage) {

          // zrusime povolenie ist do downhillu
          gamePhaseState().isAllowedToTuck = false
          this.localStateImage = false

        }

      }

    }

  }

  /**
   * Nastavenie farby pre button
   * @param color - Farba
   */
  private setButtonColor(color: SmallActionButtonColors): void {

    if (this.actualColor === color) return

    this.actualColor = color

    // zmenime farbu
    gamePhaseState().downhillButtonColor = color

  }

  /**
   * nastavime farbu obrazka
   */
  private decideButtonColor(): void {

    let color = SmallActionButtonColors.yellow
    if (this.athlete.isTuck) color = SmallActionButtonColors.green

    this.setButtonColor(color)

  }

  /**
   * Update metoda ktoru mame pouzit externe
   */
  public update(): void {

    this.checkIfActionMustHappen()
    this.makeOperationsInUiState()

  }

  /**
   * Resetovanie veci
   */
  public reset(): void {

    this.isDownhillAllowed = false
    this.localStateImage = true
    this.actualColor = SmallActionButtonColors.yellow

  }

}
