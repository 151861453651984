/**
 * Config na max hodnotu speed baru
 */
export const speedBarMaxValueConfig = {

  /** Taka konstanta */
  SPEED_RATE_DELTA: 1,

  /** Defaultna maximalna hodnota speed baru */
  defaultMaxSpeedBarValue: 100,

  /** Najnizsia hodnota framechangeu */
  lowerLimit: 5,

  /** Hodnota gradientu zmeny */
  gradientToChange: 3,

  /** Hodnota na vypocitanie minValue od ratebaru */
  minValueCalcVal: -50,

  /** Delta spomalovania rychlosti */
  speedDeltaDown: 1,

  /** Limit pre vypocet max hodnoty */
  limitForCalculations: 180,

  /** Limit pre znizovanie hodnoty */
  limitForDecreasingValue: 80,

  /** Limit pre zvysovanie hodnoty */
  limitForIncreasingValue: 100,

  /** Koeficient pre narast */
  increaseCoef: 119,

  /** Koeficient pre ubytok - pre odpocitanie */
  decreaseCoef1: 60,

  /** Koeficient pre ubytok - pre vynasobanie */
  decreaseCoef2: 1.5,

  /** Koeficient pre vypocet poctu frameov s pomocou gradientu */
  gradientCoefFrames: 30

}
