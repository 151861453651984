import type { DisplayMessage } from '@/app/types'
import {
  actionButtonState,
  heartRateState,
  inputsState,
  movementState,
  startMessageState,
  uiState
} from '@/stores'
import { modes } from '@powerplay/core-minigames'

export class StartPhaseStateManager {

  public disableInputs(): void {

    inputsState().disabled = true
    actionButtonState().disabled = true

  }

  public enableInputs(disabled = false): void {

    inputsState().$patch({
      isVisible: true,
      disabled: disabled
    })

  }

  public showStartMessageInUI(message: DisplayMessage): void {

    startMessageState().$patch({
      showMessage: true,
      messageText: message.message,
      messageColor: message.color
    })

  }

  public postIntroUiState(): void {

    uiState().$patch({
      showTimeKeeper: false,
      showSplitTimes: false,
      showFinishTopBox: false,
      showTrainingLayout: modes.isTrainingMode(),
      isTraining: modes.isTrainingMode()
    })
    movementState().onlyHorizontal = true
    inputsState().disabled = false

  }

  public finishPhaseUiState(): void {

    uiState().$patch({
      showTimeKeeper: true,
      showSplitTimes: !modes.isTrainingMode(),
      showFinishTopBox: false,
      showTrainingLayout: modes.isTrainingMode(),
      isTraining: modes.isTrainingMode()
    })
    heartRateState().showRate = true

  }

  public enableStartInputs(): void {

    console.log('enable start inputs')
    inputsState().disabled = false
    actionButtonState().$patch({
      isStart: true,
      disabled: false
    })

  }

  public disableStartMessageInUI(message: DisplayMessage): void {

    startMessageState().$patch({
      showMessage: false,
      messageText: message.message,
      messageColor: message.color
    })

  }

}

export const startPhaseStateManager = new StartPhaseStateManager()
