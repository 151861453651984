import {
  THREE,
  game,
  cameraManager,
  errorManager,
  modes,
  playersManager,
  gsap
} from '@powerplay/core-minigames'
import {
  modelsConfig,
  gameConfig,
  pathsConfig
} from '../../config'
import {
  DisciplinePhases,
  ModelsNames,
  type StartPositionsData,
  Sides,
  TutorialEventType
} from '../../types'
import { PlayerVelocityManager } from './PlayerVelocityManager'
import { Athlete } from '../athlete'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import type { RunningPhase } from '@/app/phases/RunningPhase/RunningPhase'
import { tutorialFlow } from '@/app/modes/tutorial/TutorialFlow'
import { blockingState } from '@/stores'
import { endManager } from '@/app/EndManager'

/**
 * Trieda pre hraca
 */
export class Player extends Athlete {

  /** tween ovlada ako dlho ma byt zobrazney blockingEffect */
  private blockingEffectTween?: gsap.core.Tween

  /**
   * Konstruktor
   * @param uuid - UUID hraca
   */
  public constructor(uuid: string) {

    super(uuid)
    this.velocityManager = new PlayerVelocityManager()

  }

  /**
   * Vratenie objektu atleta
   * @returns Objekt atleta
   */
  protected getObject(): THREE.Object3D {

    const meshSkierName = modelsConfig[ModelsNames.skier]?.mainMeshNames?.[0]
    if (!meshSkierName) {

      throw new Error(errorManager.showBox('Mesh name for skier was not defined'))

    }

    return game.getObject3D(meshSkierName)

  }

  /**
   * Vytvorenie lyziara
   * @param startData - Startovacie data
   */
  public create(startData: StartPositionsData): void {

    console.log('vytvaram hraca...', startData)

    this.uuid = playersManager.getPlayer().uuid
    let startDataEdited = startData
    if (modes.isTutorial()) {

      startDataEdited = { row: 1,
        pathIndex: 4 }

    }
    super.create(startDataEdited, 'Player', ModelsNames.skier)

  }

  /**
   * Konecna akcia pre hraca
   */
  public finishAction(): void {

    // najskor musime ukoncit moznost pohybovych animacii
    this.activeUpdatingMovementAnimations = false

    // reset kamery
    cameraManager.getMainCamera().up.set(0, 1, 0)

  }

  /**
   * changes config of camera
   * @param idealOffset - ideal shift of camera from player
   * @param idealLookAt - ideal place for camera to look at
   * @param coefSize - how fast should camera move (0-1)
   * @param changeLerp - how fast changes should be applied (0-1)
   */
  public changeCameraSettings(
    idealOffset?: THREE.Vector3,
    idealLookAt?: THREE.Vector3,
    coefSize?: number,
    changeLerp?: number
  ): void {

    cameraManager.changeIdeals(
      idealOffset,
      idealLookAt,
      coefSize,
      changeLerp
    )

  }

  /**
   * nastavime camera settings podla game configu
   *
   * @param lerpSize - volitelny iny lerp ako v game configu
   */
  public setGameCameraSettings(lerpSize = gameConfig.cameraConfig.changeLerp): void {

    this.changeCameraSettings(
      gameConfig.cameraConfig.idealOffset,
      gameConfig.cameraConfig.idealLookAt,
      gameConfig.cameraConfig.coefSize,
      lerpSize
    )

  }

  /**
   * Spravanie pri dosiahnuti ciela
   */
  public finishReached(): void {

    super.finishReached()

    const runningPhase = disciplinePhasesManager
      .getDisciplinePhaseManager(DisciplinePhases.running) as RunningPhase
    runningPhase.finishPhase()
    tutorialFlow.eventActionTrigger(TutorialEventType.finish)

  }

  /**
   * Zobrazenie blocking grafiky
   * @param side - strana narazu
   */
  public showBlockingEffect(side: Sides | undefined): void {

    blockingState().$patch({
      side,
      isVisible: true
    })
    this.blockingEffectTween?.kill()
    this.blockingEffectTween = gsap.to({}, {
      duration: 0.5,
      onComplete: () => {

        this.hideBlockingEffect(side)

      }
    })

    if (!modes.isTutorial()) return
    if (
      side !== undefined &&
            this.hillLinesManager.actualPathIndex > 0 &&
            this.hillLinesManager.actualPathIndex < pathsConfig.trackNumbers.length - 1
    ) {

      console.log(side, this.hillLinesManager.actualPathIndex, pathsConfig.trackNumbers.length + 1)
      tutorialFlow.eventActionTrigger(TutorialEventType.pathBlocked)

    }

  }

  public changePath(side: Sides): boolean {

    endManager.pathChangeCount += 1

    return super.changePath(side)


  }

  /**
   * Schovanie blocking grafiky
   */
  private hideBlockingEffect(side: Sides | undefined): void {

    blockingState().$patch({
      side,
      isVisible: false
    })

  }

}

export const player = new Player('')
