import {
  requestManager,
  gameStats,
  LoadingStatsTypes
} from '@powerplay/core-minigames'
import { globalErrorHandler } from './globalErrorHandler'

import type { App } from 'vue'
export type EnvConfig = Record<string, string>

export const setStartTime = (): void => {

  gameStats.setLoadingStats(LoadingStatsTypes.startTime)

}

const createRequestManager = (env?: EnvConfig): void => {

  const options = {
    gameToken: (env || import.meta.env).VITE_APP_GAME_TOKEN,
    host: (env || import.meta.env).VITE_APP_HOST,
    isLocal: Number((env || import.meta.env).VITE_APP_LOCAL) === 1,
    redirect: (env || import.meta.env).VITE_APP_REDIRECT,
    redirectPage: (env || import.meta.env).VITE_APP_REDIRECT_PAGE
  }
  requestManager.setEnvData(options)
  requestManager.create()

}

export const initializeCoreAndError = (app: App<Element>, env?: EnvConfig): void => {

  createRequestManager(env)
  globalErrorHandler(app)

}
