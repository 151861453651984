import type { SplitTimeData } from '@powerplay/core-minigames'
import { defineStore } from 'pinia'

export interface SplitTimeState {
  splitTimes: string[],
  actualTime: string,
  splitTimeData: SplitTimeData[],
  rerenderKey: string,
  showPlayerTime: boolean
}

const initialState = (): SplitTimeState => ({
  splitTimes: [],
  actualTime: '',
  splitTimeData: [],
  rerenderKey: '',
  showPlayerTime: false
})

export const splitTimeState = defineStore('splitTimeState', {
  state: initialState,
  actions: {
    addSplitTime(splitTime: string) {

      this.splitTimes.push(splitTime)

    }
  }
})


