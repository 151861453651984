<template>
  <div
    v-if="startPhaseState.showBar && tutorialState.showBarStart"
    class="nomobile-start-bar"
  >
    <start-efficiency-bar
      :forced-size="100"
      :glow="true"
      is-mobile
    />
  </div>
</template>

<script lang="ts">
import StartEfficiencyBar from '@/components/Inputs/StartEfficiencyBar.vue'

import { defineComponent } from 'vue'
import {
  startPhaseState,
  tutorialState
} from '@/stores'

export default defineComponent({
  name: 'TutorialBars',
  components: {
    StartEfficiencyBar,
  },
  data() {

    return {
      startPhaseState: startPhaseState(),
      tutorialState: tutorialState()
    }

  }
})
</script>

<style scoped lang="less">
.tutorial {
    .nomobile-start-bar {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 375px;
        height: 375px;
    }
}
</style>
