<template>
  <section
    v-if="heartRateState.showRate"
    class="heart-rate-position"
  >
    <heart-monitor-box
      :heart-rate="String(heartRateState.heartRate)"
      :glow="glow"
    />
  </section>
</template>

<script lang="ts">
import { HeartMonitorBox } from '@powerplay/core-minigames-ui'

import { defineComponent } from 'vue'
import { heartRateState } from '@/stores'

export default defineComponent({
  name: 'HearthRateComponent',
  components: {
    HeartMonitorBox
  },
  props: {
    glow: {
      type: Boolean,
      default: false
    }
  },
  data() {

    return {
      heartRateState: heartRateState()
    }

  }
})

</script>
