import {
  MainCore,
  game,
  timeManager,
  CameraTypes,
  settings,
  SettingsTypes,
  gameStats,
  CustomEvents,
  THREE,
  AudioManager,
  CameraStates,
  cameraManager,
  requestManager,
  TimesTypes,
  fpsManager,
  appWSM2021Config,
  modes,
  playersManager,
  AppWSM2021DifficultyTypes,
  playersConfig,
  PlayersSecondResultTypes,
  tutorialManager,
  corePhasesManager,
  CorePhases
} from '@powerplay/core-minigames'
import {
  audioConfig,
  batchingConfig,
  modelsConfig,
  texturesConfig,
  debugConfig,
  cameraConfig,
  gameConfig,
  trainingConfig,
  velocityConfig,
  splitTimesConfig,
  translatesReplacements
} from './config'
import {
  TexturesNames,
  MaterialsNames,
  DisciplinePhases
} from './types'
import {
  debugState,
  trainingState,
  tutorialState,
  uiState
} from '@/stores'
import { hill } from './entities/hill/Hill'
import {
  Player,
  player
} from './entities/player'
import { inputsManager } from './InputsManager'
import { disciplinePhasesManager } from './phases/DisciplinePhasesManager'
import { appWSM2021AllDifficultiesConfig } from './config/appWSM2021AllDifficultiesConfig'
import { materialsConfig } from './config/materialsConfig'
import { trainingTasks } from './modes/training/TrainingTasks'
import { stateManager } from './StateManager'
import { tutorialFlow } from './modes/tutorial/TutorialFlow'
import { pathAssets } from '@/globals/globalvariables'
import { ParticleEffects } from './ParticleEffects'
import { opponentsManager } from './entities/opponent/OpponentsManager'
import { linesManager } from './entities/hill/LinesManager'
import type { Opponent } from './entities/opponent/Opponent'
import { checkpointManager } from './modes/training'
import { endManager } from './EndManager'
import * as Sentry from '@sentry/vue'
import { loadingState } from '@powerplay/core-minigames-ui'

/**
 * Hlavna trieda pre disciplinu
 */
export class Main {

  /** Hlavna trieda z CORE */
  private mainCore: MainCore

  // Aktualna pozicia kamery
  private actualCameraPosition = new THREE.Vector3()

  // Aktualna rotacia kamery
  private actualCameraQuaternion = new THREE.Quaternion()

  /** Pause prveho tutorialu */
  private pausedFirstTutorial = false

  /** Partikle Effect */
  private particleEffects!: ParticleEffects

  /**
   * Konstruktor
   */
  public constructor() {

    this.addListeners()
    // pripravenie konfigov pre WSM 2021 - musime kontrolvat takto kvoli typescriptu
    if (modes.isAppWSM2021()) {

      appWSM2021Config.data = appWSM2021AllDifficultiesConfig[
        modes.mode as unknown as AppWSM2021DifficultyTypes // small TS hack :)
      ]

    }

    if (modes.isTutorial()) {

      // nastavime loading pre tutorial TODO zmen text na nazov discipliny
      loadingState().tutorial = {
        isEnabled: true,
        text: 'disciplineName8'
      }

    }

    /*
     * Nastavenie players konfigov, aby sa dobre zoradovali a mali dobre empty vysledky
     * nemusime zatial nic nastavovat, lebo berieme default hodnoty z konfigu
     */
    this.setPlayersConfig()

    // nastavime penalizaciu
    timeManager.setPenaltySecondsForOne(gameConfig.penaltySeconds)

    // spustime CORE veci a pokial vsetko je v pohode, pusti sa INIT metoda
    this.mainCore = new MainCore(
      {
        meshesCastShadows: materialsConfig[MaterialsNames.skier].meshesArray || [],
        materials: materialsConfig,
        callbacks: {
          inputs: {
            callbackLeft: inputsManager.onKeyLeft,
            callbackRight: inputsManager.onKeyRight,
            callbackUp: inputsManager.onKeyUp,
            callbackDown: inputsManager.onKeyDown,
            callbackAction: inputsManager.onKeyAction,
            callbackAction2: inputsManager.onKeyAction2,
            callbackAction3: inputsManager.onKeyAction3,
            callbackExit: inputsManager.onKeyExit,
            callbackPrepareVideo: inputsManager.onKeyPrepareVideo
          },
          setSpecialDataFromInitRequest: this.setSpecialDataFromInitRequest,
          createAssets: this.createAssets,
          beforeGameStart: this.beforeGameStart,
          updateBeforePhysics: this.updateBeforePhysics,
          updateAfterPhysics: this.updateAfterPhysics,
          updateAnimations: this.updateAnimations
        },
        batching: batchingConfig,
        debugConfig,
        numberOfAttempts: gameConfig.numberOfAttempts,
        inputSchema: 'biathlon'
      },
      translatesReplacements,
      {
        textures: texturesConfig,
        models: modelsConfig,
        audio: audioConfig
      }
    )

    this.initialSetup()

  }

  /**
   * Metoda na overenie a zobrazenie FPS
   */
  private checkFpsRequest(): void {

    if (stateManager.getFpsStarted()) {

      const settingsQuality = settings.getSetting(SettingsTypes.quality)
      const fpsData = {
        averageFps: fpsManager.getAverageFpsByQuality(settingsQuality),
        actualFps: fpsManager.getActualFpsByQuality(settingsQuality),
        actualAverageFps: fpsManager.getActualAverageFps()
      }
      stateManager.setFpsData(fpsData)

    }

  }

  /**
   * Pridanie listenerov
   */
  private addListeners() {

    window.addEventListener(CustomEvents.readyForDisciplineInit, this.init)
    window.addEventListener(CustomEvents.loadingProgress, this.loadingProgress)
    // nastavime pocuvanie na zaciatok disciplinovej fazy z CORE
    window.addEventListener(
      CustomEvents.startDisciplinePhase,
      disciplinePhasesManager.setStartPhase
    )

  }

  /**
   * Inicializacny setup
   */
  private initialSetup() {

    const localEnv = Number(import.meta.env.VITE_APP_LOCAL) === 1
    this.mainCore.setIsLocalEnv(localEnv)
    game.setIsLocalEnv(localEnv)

    // lokalne si davame ID discipliny, aby sme nemuseli menit v GET parametroch stale
    if (localEnv) requestManager.disciplineID = 8

    AudioManager.PATH_ASSETS = pathAssets
    disciplinePhasesManager.create()

    /*
     * listener na zistenie appky, ze sme v background mode a mame dat pauzu, aby sme setrili
     * prostriedky a aby nehrali zvuky
     */
    window.addEventListener(CustomEvents.toggleBackgroundMode, () => {

      tutorialState().settings = true

    }, false)

  }

  /**
   * Vratenie ignorovanych nazvov textur
   * @returns Pole nazvov ignorovanych textur
   */
  private getIgnoredTexturesNames(): string[] {

    const allRaceTextures = [
      TexturesNames.skierRaceBlackMan,
      TexturesNames.skierRaceBlackWoman,
      TexturesNames.skierRaceMulattoMan,
      TexturesNames.skierRaceMulattoWoman,
      TexturesNames.skierRaceWhiteMan,
      TexturesNames.skierRaceWhiteWoman
    ]

    const usedTextures: string[] = []

    // pridame hraca
    const playerInfo = playersManager.getPlayer()
    usedTextures.push(`${playerInfo.sex}/${TexturesNames.skierRacePrefix}${playerInfo.race}`)

    // pridame superov, ak su
    opponentsManager.getOpponentsIds().forEach((uuid) => {

      const opponentInfo = playersManager.getPlayerById(uuid)
      usedTextures.push(`${opponentInfo?.sex}/${TexturesNames.skierRacePrefix}${opponentInfo?.race}`)

    })

    // vysledok bude rozdiel dvoch poli
    return allRaceTextures.filter(x => !usedTextures.includes(x))

  }

  /**
   * Inicializacia main core
   */
  public init = (): void => {

    // musime zakazat default shadow plane, lebo budeme pouzivat teren
    game.shadowsManager.enableCreatingShadowPlaneOnStart = false
    game.shadowsManager.setShadowCameraFrustumPlaneDimensions(12, 12, 12, 12)

    // ak treba, tak musime prepisat data
    opponentsManager.rewriteOpponentsData()

    this.mainCore.init(
      undefined,
      undefined,
      undefined,
      undefined,
      opponentsManager.getOpponentsIds(),
      this.getIgnoredTexturesNames(),
      TexturesNames.skierRacePrefix
    )

    const tweenSettingsForCameraStates = modes.isTrainingMode() ?
      trainingConfig.tweenSettingsForCameraStates :
      cameraConfig.tweenSettingsForCameraStates

    this.mainCore.setTweenSettingsForStates(tweenSettingsForCameraStates)

    cameraManager.changeBaseRenderSettings(0.1, 850)
    trainingTasks.initTraining()
    timeManager.setActive(TimesTypes.total, true)

    // UI update
    stateManager.allowDirectionState()

    // triggers.splitTimeManager.setSplitCount()

  }

  /**
   * Nastavenie konfigu pre hracov
   */
  private setPlayersConfig(): void {

    playersConfig.secondResultType = PlayersSecondResultTypes.numberArray

  }

  /**
   * Zobrazenie progresu loadingu
   */
  private loadingProgress = (): void => {

    gameStats.setNextLoadingPart()
    loadingState().loadingProgress = gameStats.getLoadingProgress()

  }

  /**
   * Nastavenie specialnych dat z init requestu
   * @param data - Specialne data
   */
  private setSpecialDataFromInitRequest = (data: unknown): void => {

    console.log(data)

    Sentry.setContext('minigame', { id: requestManager.MINIGAME_START_ID })

  }

  /**
   * Nastavenie assetov
   */
  private createAssets = (): void => {

    hill.create()
    linesManager.init()

    // musime si najskor urcit, kto bude na ktorych poziciach zacinat
    disciplinePhasesManager.setStartPositionsForPlayers()

    if (modes.isEventBossFight()) {

      splitTimesConfig.splitRecordsToShow = 2

    }

    player.create(disciplinePhasesManager.dataForPlayersStartPositions[playersManager.getPlayer().uuid])
    opponentsManager.create(disciplinePhasesManager.dataForPlayersStartPositions)

    // schovame mesh supera, ktory sa pouziva na klonovanie
    const opponentMesh = game.scene.getObjectByName('skier_opponent')
    if (opponentMesh) opponentMesh.visible = false

    // tiene
    hill.setUpShadows()

    // CHECKPOINTS
    checkpointManager.create()

    this.setUpDebug()

    // Partikle
    this.particleEffects = new ParticleEffects()

  }

  /**
   * puts singletons into window object
   */
  private setUpDebug(): void {

    if (!Number(import.meta.env.VITE_APP_LOCAL)) return

    const debug = {
      hill,
      inputsManager,
      player,
      disciplinePhasesManager,
      cameraManager: cameraManager,
      setHUDVisibility: () => (debugState().isHudActive = true),
      pauseGame: () => {

        if (game.paused) game.resumeGame()
        else game.pauseGame()

      },
      scene: game.scene,
      game,
      THREE,
      linesManager,
      opponentsManager,
      tutorialManager
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).debug = debug

  }

  /**
   * Nastavenie alebo spustenie veci pred startom hry
   */
  private beforeGameStart = (): void => {

    stateManager.setBeforeGameStartPhase()

    // nastavime plynutie casu
    this.setTimeSpeedRunning()

  }

  /**
   * Nastavenie koeficientu plynutia casu podla atributov
   */
  private setTimeSpeedRunning(): void {

    const playerStrength = playersManager.getPlayer().attribute.total

    const { above1000, under1000, under100 } = gameConfig.timeSpeedRunningCoefs

    if (playerStrength > 1000) {

      gameConfig.timeSpeedRunning = 1 + (2000 - playerStrength) * above1000

    } else if (playerStrength > 100) {

      gameConfig.timeSpeedRunning = 1.3 + (1000 - playerStrength) * under1000

    } else {

      gameConfig.timeSpeedRunning = 1.84 + (100 - playerStrength) * under100

    }

    console.log('Plynutie casu (timeSpeedRunning) je ', gameConfig.timeSpeedRunning)

  }

  /**
   * Spustenie veci v update pred vykonanim fyziky
   */
  private updateBeforePhysics = (): void => {

    disciplinePhasesManager.update()
    player.updateBeforePhysics()
    opponentsManager.updateBeforePhysics()
    this.checkFpsRequest()
    if (disciplinePhasesManager.actualPhase > DisciplinePhases.start) {

      this.setSlipStreams()

    }

  }

  /**
   * Spustenie veci v update po vykonani fyziky
   */
  private updateAfterPhysics = (delta: number): void => {

    if (
      !corePhasesManager.isActivePhaseByType(CorePhases.intro) &&
      !corePhasesManager.isActivePhaseByType(CorePhases.discipline)
    ) return
    this.particleEffects.update()
    if (requestManager.isFirstTrainingTutorial() && !this.pausedFirstTutorial) {

      this.pausedFirstTutorial = true
      console.log(requestManager.TUTORIAL_ID)
      trainingState().firstTutorialMessage = true
      game.pauseGame()
      return

    }
    player.updateAfterPhysics()
    opponentsManager.updateAfterPhysics()
    hill.manageShadowPlanes()

    this.actualCameraPosition.copy(player.getPosition())
    if (
      disciplinePhasesManager.actualPhase > DisciplinePhases.start &&
            disciplinePhasesManager.actualPhase < DisciplinePhases.finish
    ) {

      this.actualCameraQuaternion.slerp(
        player.getQuaternion(),
        gameConfig.cameraQuaternionLerp
      )

    } else {

      this.actualCameraQuaternion.copy(player.getQuaternion())

    }

    const instalerp = [DisciplinePhases.start, DisciplinePhases.preStart].includes(disciplinePhasesManager.actualPhase)

    cameraManager.move(
      this.actualCameraPosition,
      this.actualCameraQuaternion,
      delta,
      [/* hill.hillMesh */],
      cameraConfig.distanceFromGround,
      cameraManager.isThisCameraState(CameraStates.disciplineOutro),
      {
        lerpCoef: instalerp ? 1 : 0.1,
        newQuat: player.getQuaternion().clone()
      }
    )

    stateManager.setUpdateTimeState()

    // triggers.splitTimeManager.update()

    if (modes.isTutorial()) tutorialFlow.update()

  }

  /**
   * Spustenie vykonavania vsetkych animacii
   * @param delta - Delta
   */
  private updateAnimations = (delta: number): void => {

    player.updateAnimations(delta)
    opponentsManager.updateAnimations(delta)

  }

  /**
   * Zmena kamery na debug, ak by sme potrebvalo
   */
  public changeCameraToDebug = (): void => {

    cameraManager.setCamera(CameraTypes.debug)

  }

  /**
   * Nastavenie slipStream pre vsetkych atletov
   */
  public setSlipStreams(): void {

    const opponents = opponentsManager.getOpponents()

    opponents.forEach(opponent => {

      this.setIsAthleteInSlipstream(opponent, [...opponents, ...[player]])

    })
    this.setIsAthleteInSlipstream(player, opponents)

  }


  /**
   * Vypocet isInSlipstream
   * @param checkedAthlete - atlet, ktoremu settujeme isInSlipStream
   * @param athletes - vsetci atleti s ktorymi porovnavame
   */
  private setIsAthleteInSlipstream(checkedAthlete: Opponent | Player, athletes: Array<Opponent | Player>): void {

    const actualPercent = checkedAthlete.hillLinesManager.getActualPercent()
    const slipstreamZone =
            velocityConfig.slipStreamZone * checkedAthlete.hillLinesManager.oneMeterInPercent
    let safeZoneBehind =
            velocityConfig.safeZoneBehind * checkedAthlete.hillLinesManager.oneMeterInPercent
    if (checkedAthlete.isEnd) {

      safeZoneBehind =
                velocityConfig.safeZoneBehindEnd * checkedAthlete.hillLinesManager.oneMeterInPercent

    }
    const sprintOffZone =
            (velocityConfig.safeZoneBehind + velocityConfig.sprintOffZone) *
            checkedAthlete.hillLinesManager.oneMeterInPercent

    checkedAthlete.isInSlipStream = false
    uiState().inSlipStream = false
    checkedAthlete.inSafeZoneOfAthlete = undefined
    checkedAthlete.inSprintOffZone = false
    const pathToCheck = checkedAthlete.hillLinesManager.actualPathIndex

    if (gameConfig.debugSlipStream) {

      this.debugSlipStream(
        checkedAthlete.hillLinesManager.playerPath?.getPointAt(actualPercent - slipstreamZone),
        checkedAthlete.uuid
      )

    }
    athletes.forEach((opponent) => {

      if (
        checkedAthlete.uuid === opponent.uuid ||
                pathToCheck !== opponent.hillLinesManager.actualPathIndex
      ) {

        return

      }

      const opponentActualPercent = opponent.hillLinesManager.getActualPercent()
      if (
        opponentActualPercent > actualPercent &&
                    opponentActualPercent - slipstreamZone < actualPercent
      ) {

        checkedAthlete.isInSlipStream = true
        checkedAthlete.athleteOfSlipStream = opponent
        if (checkedAthlete.playable && checkedAthlete.isSkating && !checkedAthlete.isEnd) {

          tutorialFlow.inSlipStream()
          endManager.framesInSlipStream += 1
          uiState().inSlipStream = true

        }

      }
      if (
        opponentActualPercent > actualPercent &&
                opponentActualPercent - safeZoneBehind < actualPercent
      ) {

        checkedAthlete.inSafeZoneOfAthlete = opponent

      }
      if (
        opponentActualPercent > actualPercent &&
                opponentActualPercent - sprintOffZone < actualPercent
      ) {

        checkedAthlete.inSprintOffZone = true

      }

    })

  }

  /**
   * Zobrazenie debug gul za hracmi, pokial siaha slipstream
   * @param position - pozicia konca slipstream
   * @param uuid - uuid atleta
   * @returns void
   */
  private debugSlipStream(position: THREE.Vector3 | undefined, uuid: string): void {

    if (!position) return
    const name = `debug-${uuid}`

    const sphere = game.scene.getObjectByName(name)

    if (sphere) {

      sphere.position.set(position.x, position.y + gameConfig.yPlayerCorrection, position.z)
      return

    }

    const geometrySphere = new THREE.SphereGeometry(0.1, 0.1, 0.1)
    const materialSphere = new THREE.MeshBasicMaterial({ color: Math.random() * 0xffffff })
    const meshSphere = new THREE.Mesh(geometrySphere, materialSphere)
    meshSphere.position.set(position.x, position.y + gameConfig.yPlayerCorrection, position.z)
    meshSphere.name = name
    game.scene.add(meshSphere)

  }

}
