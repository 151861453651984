<template>
  <div
    class="small-action-buttons-sub-positions"
    :class="isMobileForced ? '' : 'is-not-mobile'"
    :style="transformCoef"
  >
    <div :class="isMobileForced ? 'bar-right' : 'bar'">
      <section
        v-if="!isMobileForced"
        class="tutorial-keyboard-header"
      >
        <shrinking-text-box
          :text="$t('webInfoSprint')"
          :font-size="28"
          :width="150"
          :height="34"
        />
      </section>
      <small-action-button
        type="cc-speed"
        :pie-percent="gamePhaseState.sprintBarState"
        :style="basicStyle"
        :disabled="isDisabledSprint()"
        :color="gamePhaseState.sprintButtonColor"
        :letter="isMobileForced ? '' : 'q'"
        :is-scaled="false"
        @mousedown.stop="performAction2"
        @mouseup.stop="endAction2"
        @touchstart.stop="performAction2"
        @touchend.stop="endAction2"
      />
    </div>
    <div
      :class="isMobileForced ? 'bar-left' : 'bar'"
      :style="barLeftStyle"
    >
      <section
        v-if="!isMobileForced"
        class="tutorial-keyboard-header"
      >
        <shrinking-text-box
          :text="$t('webInfoTuck')"
          :font-size="28"
          :width="150"
          :height="34"
        />
      </section>
      <small-action-button
        :disabled="isDisabledTuck()"
        type="cc-tuck"
        :pie-percent="100"
        :style="basicStyle"
        :color="gamePhaseState.downhillButtonColor"
        :letter="isMobileForced ? '' : 'e'"
        :is-scaled="false"
        @mousedown.stop="performAction"
        @mouseup.stop="endAction"
        @touchstart.stop="performAction"
        @touchend.stop="endAction"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {
  SmallActionButton,
  ShrinkingTextBox,
  WindowAspect
} from '@powerplay/core-minigames-ui'
import { inputsManager } from '@powerplay/core-minigames'

import { defineComponent } from 'vue'
import {
  gamePhaseState,
  sprintState,
  tuckState
} from '@/stores'

export default defineComponent({
  components: {
    SmallActionButton,
    ShrinkingTextBox
  },
  mixins: [WindowAspect],
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    isMobileForced: {
      type: Boolean,
      default: false
    }
  },
  data() {

    return {
      gamePhaseState: gamePhaseState()
    }

  },
  computed: {
    basicStyle() {

      if (!this.isMobileForced) return {}

      return {
        'align-items': 'flex-end',
        padding: ' 0 0 10px'
      }

    },
    transformCoef() {

      if (this.isMobileForced) return {}
      return { transform: `scale(${this.scaleCoef})`,
        transformOrigin: 'top right' }

    },
    barLeftStyle() {

      if (this.isMobileForced) return {}
      return { paddingLeft: '70px' }

    }
  },
  methods: {
    performAction() {

      tuckState().isTuck = true

    },
    endAction() {

      tuckState().isTuck = false
      // musime dat manulane ukoncenie buttonu, aby sa ukoncil takisto actionPressed v inputs
      inputsManager.handleMouseUp()

    },
    performAction2() {

      if (!gamePhaseState().isAllowedToSprint) return
      sprintState().isSprinting = true

    },
    endAction2() {

      // v tutoriali sa endAction2 na webe davalo a sposobovalo to problemy
      if (sprintState().tutorialFreeze) return

      sprintState().isSprinting = false
      // musime dat manulane ukoncenie buttonu, aby sa ukoncil takisto actionPressed v inputs
      inputsManager.handleMouseUp()

    },
    isDisabledTuck() {

      return !gamePhaseState().isAllowedToTuck || this.disabled || gamePhaseState().disabledSmallAction

    },
    isDisabledSprint() {

      return this.disabled || gamePhaseState().disabledSmallAction || !gamePhaseState().isAllowedToSprint

    }

  },
})

</script>
<style lang="less">
.small-action-buttons-sub-positions {
    position: absolute;
    top: -60px;
    right: 40px;
    width: 100%;
    height: 200px;
    display: flex;

    &.is-not-mobile {
        top: 20px;
        left: unset;
        display: flex;
        justify-content: space-around;
    }
        .small-action-button-container {
            width: 100%;
            height: 100%;
            position: relative;
        }
    .bar {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 50%;
        .small-action-button-container {
            position: relative;
            pointer-events: auto;
        }
    }

    .bar-right {
        width: 100%;
        height: 100%;
        padding: 0 20px;

        .small-action-button-wrapper {
            justify-content: flex-end;
            align-items: flex-end;
            width: 100%;
            height: 100%;
        }
    }
    .bar-left {
        width: 100%;
        height: 100%;
        padding: 0 20px;

        .small-action-button-wrapper {
            justify-content: flex-start;
            align-items: flex-end;
            width: 100%;
            height: 100%;
        }
    }
    .tutorial-keyboard-header {
        width: 200px;
        height: 36px;
        padding: 0 20px;
        margin-bottom: 20px;

        border: 2px solid;
        border-image-slice: 1;
        border-width: 2px;
        border-image-source: linear-gradient(
            to right, transparent 0%, #528ebf 25%,  #528ebf 75%, transparent 100%
        );
        border-right: none;
        border-left: none;
        background-image: linear-gradient(to right, transparent, #18334e, #215d84, transparent);

        text-shadow: 0.6px 0.8px 0.9px rgba(0, 0, 0, 0.71);
        font-family: Roboto;
        font-size: 28px;
        font-weight: bold;
        font-style: italic;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
</style>
@/stores