import { defineStore } from 'pinia'


export interface ActionButtonState {
  isStart: boolean,
  touchStart: boolean,
  showJoystick: boolean,
  disabled: boolean
}

const initialState = (): ActionButtonState => ({
  isStart: true,
  touchStart: false,
  showJoystick: false,
  disabled: true
})

export const actionButtonState = defineStore('actionButtonState', {
  state: initialState
})
