import { defineStore } from 'pinia'

export interface MainState {
  maxSpeed: number,
  speed: number,
  gradient: number,
  sprintBonus: number,
  forces: {
    forwardForce: number,
    forwardForceTuck: number,
    forwardForceSprint: number,
    backwardForce: number
  },
  BPMBase: number
}

const initialState = (): MainState => ({
  maxSpeed: 1,
  speed: 0,
  gradient: 0,
  sprintBonus: 0,
  forces: {
    forwardForce: 0,
    forwardForceTuck: 0,
    forwardForceSprint: 0,
    backwardForce: 0
  },
  BPMBase: 0
})

export const mainState = defineStore('mainState', {
  state: initialState
})
