import {
  blurState,
  tableState,
  trainingResultsState
} from '@/stores'
import {
  corePhasesManager,
  modes,
  playersManager,
  requestManager,
  type TrainingDataFromResultsRequest,
  trainingManager,
  gameStats,
  type PlayerInfo,
  minigameConfig,
  fpsManager,
  type TournamentDataFromResultsRequest,
  gsap
} from '@powerplay/core-minigames'
import { pathsConfig } from './config'
import { splitTimeManager } from './entities/athlete/SplitTimeManager/SplitTimeManager'
import { player } from './entities/player'
import { tutorialFlow } from './modes/tutorial/TutorialFlow'
import { tutorialObjectives } from './modes/tutorial/TutorialObjectives'
import { disciplinePhasesManager } from './phases/DisciplinePhasesManager'
import {
  type OpponentResult,
  TutorialObjectiveIds,
  type SaveResultsDataToSend
} from './types'
import { trainingTasks } from './modes/training'
import { stateManager } from './StateManager'
import { waitingState } from '@powerplay/core-minigames-ui'

/**
 * Trieda pre koniec discipliny
 */
export class EndManager {

  /** ci uz bol result poslany alebo nie */
  private resultSent = false

  /** Ci uz niekto z hracov prisiel do cielovej rovinky */
  public firstPlayerBeforeFinish = false

  /** kvalita startu */
  public startQuality = 0

  /** framy v slipStreame */
  public framesInSlipStream = 0

  /** pocet zmien drah */
  public pathChangeCount = 0

  /** kolko framov stravil v ktorej drahe */
  public pathSpentFrames = [0, 0, 0, 0, 0, 0, 0]

  /** sucet hodnot tempa pre priemer */
  private sumOfTempo = 0

  /** pocet framov pocitania tempa */
  private framesCountingTempo = 0

  /** pocet framov maximalneho tempa */
  private framesMaxTempo = 0

  /**
   * Poslanie requestu pre konecne logovanie
   */
  public sendLogEnd(): void {

    // ak uz mame nastavene, tak uz viac nenastavujeme
    if (Object.keys(gameStats.getDisciplineDataToLog()).length > 0) return

    const { framesInMainPath, framesInSidePath } = this.frameInPaths()

    // zaznamename nejake info pre logy
    gameStats.setDisciplineDataToLog({
      time: playersManager.players[0].resultsArr?.[corePhasesManager.disciplineActualAttempt - 1].main || 0,
      dnf: playersManager.dnf,
      split: playersManager.dnf ? [] : splitTimeManager.playerSplitTimes,
      qualities: {
        start: this.startQuality / 100
      },
      playerPosition: modes.isTrainingMode() ?
        trainingTasks.position + 1 :
        playersManager.getPlayerActualPosition(player.uuid),
      trainingTasks: modes.isTrainingMode() ?
        trainingManager.getTrainingTasks().map(task => task.value) :
        [],
      tutorialData: modes.isTutorial() ? this.getTutorialLogs() : [],
      secondsInSlipStream: this.framesInSlipStream / fpsManager.fpsLimit,
      pathChangeCount: this.pathChangeCount,
      pathSpentTimeNumber: this.calculatePathSpentMostTime(),
      averageTempo: this.sumOfTempo / this.framesCountingTempo,
      percentOfTimeMaxTempo: 100 / this.framesCountingTempo * this.framesMaxTempo || 0,
      percentOfTimeSideTrack: 100 / (framesInMainPath + framesInSidePath) * framesInSidePath || 0
    })

    console.log('LOG to send', gameStats.getDisciplineDataToLog())

  }

  /**
   * ziskame pole tutorialovych logov
   *
   * @returns - pole tutorialovych logov
   */
  private getTutorialLogs(): (number | boolean)[] {

    /**
     * completed - Ci hrac uspesne dokoncil vsetky ulohy [boolean]
     * exited - Ci bol ukonceny tutorial [boolean]
     */

    return [
      tutorialFlow.failedStartCount, // falseStartCount
      tutorialObjectives
        .checkIfObjectivePassed(tutorialObjectives.getObjectiveById(TutorialObjectiveIds.start)), // wasStartSuccess
      tutorialObjectives
        .checkIfObjectivePassed(tutorialObjectives.getObjectiveById(TutorialObjectiveIds.slipStream)),
      // wasSlipStreamSuccess
      tutorialObjectives.isAllObjectivesPassed(), // completed
      disciplinePhasesManager.prematureEnded // exited
    ]

  }

  /**
   * Vybratie dat a poslanie do funkcie z core-minigames
   */
  public sendSaveResult(): void {

    // ak uz bol result poslany, neposielame ho znova
    if (this.resultSent) return

    this.resultSent = true

    // TODO TEMP - zatial takto, ked bude hotovy tutorial, tak sa to bude posielat tam
    requestManager.sendTutorialRequest()
    if (modes.isTutorial()) return

    const opponentsResults = [] as OpponentResult[]
    playersManager.players.filter((playerInfo: PlayerInfo, index: number) => {

      return !playerInfo.playable && index <= 7

    }).forEach((playerInfo: PlayerInfo) => {

      opponentsResults.push({
        uuid: playerInfo.uuid,
        time: playerInfo.resultsArr?.[
          corePhasesManager.disciplineActualAttempt - 1
        ].main || minigameConfig.dnfValue
      })

    })

    const data: SaveResultsDataToSend = {
      time: playersManager.players[0].resultsArr?.[
        corePhasesManager.disciplineActualAttempt - 1
      ].main || 0,
      positions: playersManager.getPlayersPositions(),
      dnf: playersManager.dnf,
      dsq: false,
      split_times: playersManager.dnf ? [] : splitTimeManager.playerSplitTimes,
      split_positions: playersManager.dnf ? [] : splitTimeManager.playerSplitPositions,
      opponentsResults

    }

    if (modes.isTrainingMode()) {

      data.trainingResults = trainingManager.getTrainingTasks().map(task => task.value)

    }

    console.log('data to send', data)

    if (modes.isTournament()) {

      waitingState().isWaiting = true
      blurState().$patch({
        isActive: true,
        isTable: true
      })
      tableState().$patch({
        resultText: modes.isTournament() || modes.isDailyLeague() ? 'provisionalResults' : 'finalStandings',
        showTable: true,
        activeState: false,
        dataTable: [],
        isStartList: false,
      })

    }

    requestManager.sendSaveResultsRequest(
      (dataCallback: TrainingDataFromResultsRequest | TournamentDataFromResultsRequest | unknown) => {

        console.log('saveRequest Successful')

        if (modes.isTrainingMode()) {

          trainingResultsState().$patch({
            data: dataCallback as TrainingDataFromResultsRequest,
            bestScore: trainingManager.bestScore,
            dataSet: true
          })

        }

        if (modes.isTournament()) {

          if (Number(import.meta.env.VITE_APP_LOCAL) === 1) {

            gsap.to({}, {
              onComplete: () => {

                disciplinePhasesManager.setOpponentsForFinishTable(dataCallback as TournamentDataFromResultsRequest)
                stateManager.afterAllRequestsDone()

              },
              duration: 5
            })

          } else {

            disciplinePhasesManager.setOpponentsForFinishTable(dataCallback as TournamentDataFromResultsRequest)

          }

        }

      },
      JSON.stringify(data)
    )

  }

  /**
   * Vypocitanie v ktorej drahe stravil najviac casu
   * @returns pathSpentMostTime
   */
  private calculatePathSpentMostTime(): number {

    let pathSpentTime = 0
    let pathIndex = 0
    this.pathSpentFrames.forEach((pathFrames, index) => {

      if (pathFrames / fpsManager.fpsLimit > pathSpentTime) {

        pathSpentTime = pathFrames / fpsManager.fpsLimit
        pathIndex = index

      }


    })
    return pathsConfig.trackNumbers[pathIndex]

  }

  /**
   * Pocitanie kolko framov stravil v hlavnych a v medzidrahach
   * @returns framesInMainPath, framesInSidePath
   */
  private frameInPaths(): {framesInMainPath: number, framesInSidePath: number} {

    let framesInMainPath = 0
    let framesInSidePath = 0
    this.pathSpentFrames.forEach((pathFrames, index) => {

      if (index % 2 === 0) {

        framesInMainPath += pathFrames

      } else {

        framesInSidePath += pathFrames

      }


    })

    return { framesInMainPath,
      framesInSidePath }

  }

  /**
   * Pocitanie tempa pre priemere
   * @param tempoValue - hodnota tempo baru
   */
  public countTempo(tempoValue: number): void {

    if (tempoValue >= player.maxSpeedBarManager.getSpeedBarMaxValue()) {

      this.framesMaxTempo += 1

    }
    this.sumOfTempo += tempoValue
    this.framesCountingTempo += 1

  }

  /**
   * Reset result
   */
  public reset(): void {

    this.resultSent = false
    this.firstPlayerBeforeFinish = false
    this.pathChangeCount = 0

  }

}

export const endManager = new EndManager()
