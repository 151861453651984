<template>
  <div
    v-if="!isMobile && gamePhaseState.showSmallActionButtons"
    class="nomobile-start-bar"
  >
    <small-action-buttons
      v-if="gamePhaseState.showBar && !actionButtonState.disabled"
      :is-mobile-forced="isMobile"
    />
    <speed-bar
      v-if="gamePhaseState.showBar && !actionButtonState.disabled"
      :is-mobile="isMobile"
    />
    <start-efficiency-bar
      v-if="startPhaseState.showBar"
      :is-mobile="isMobile"
    />
  </div>
</template>

<script lang="ts">
import StartEfficiencyBar from './StartEfficiencyBar.vue'
import SmallActionButtons from './SmallActionButtons.vue'
import SpeedBar from './SpeedBar.vue'

import { defineComponent } from 'vue'
import {
  actionButtonState,
  gamePhaseState,
  startPhaseState
} from '@/stores'

export default defineComponent({
  name: 'EfficiencyComponent',
  components: {
    StartEfficiencyBar,
    SmallActionButtons,
    SpeedBar
  },
  props: {
    isMobile: {
      type: Boolean,
      required: true
    }
  },
  data() {

    return {
      startPhaseState: startPhaseState(),
      gamePhaseState: gamePhaseState(),
      actionButtonState: actionButtonState()
    }

  }
})

</script>

<style lang='less' scoped>
.nomobile-start-bar {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 375px;
  height: 375px;
}
</style>
