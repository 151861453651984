import { defineStore } from 'pinia'

export interface ShinyTextsState {
  texts: { name: string, active: boolean }[]
}

const initialState = (): ShinyTextsState => ({
  texts: []
})

export const shinyTextsState = defineStore('shinyTextsState', {
  state: initialState,
  actions: {
    setTextByName(newText: { name: string, active: boolean }) {

      const index = this.texts.findIndex((text) => {

        return text.name === newText.name

      })
      if (index >= 0) {

        this.texts[index] = newText

      }

    },
    addText(newText: { name: string, active: boolean }) {

      this.texts.push(newText)

    }
  }
})
