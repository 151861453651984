import {
  gsap,
  CameraStates,
  corePhasesManager,
  modes,
  cameraManager,
  audioManager,
  timeManager,
  TimesTypes
} from '@powerplay/core-minigames'
import {
  type DisciplinePhaseManager,
  type DisplayMessage,
  AudioNames
} from '../../types'

import {
  actionButtonState,
  inputsState,
  shinyTextsState,
  startPhaseState,
  tutorialState,
  uiState
} from '@/stores'
import { player } from '../../entities/player'
import { gameConfig } from '../../config'
import { startPhaseStateManager } from './StartPhaseStateManager'
import { tutorialFlow } from '@/app/modes/tutorial/TutorialFlow'
import { tutorialUIChange } from '@/app/modes/tutorial/TutorialUIChange'
import { opponentsManager } from '@/app/entities/opponent/OpponentsManager'

/**
 * Trieda pre startovaciu fazu
 */
export class StartPhaseManager implements DisciplinePhaseManager {

  /** ci sa deje nieco skipnutelne */
  private skippable = true

  /** ci uz je mozne odstartovat */
  private startable = false

  /** ci uz zobrazit ui player-info-avatar */
  private showName = false

  /** ci bolo skipnute */
  private skipped = false

  /** ci faza skoncila */
  public ended = false

  /** Specialne zobrazenie baru v tutoriali */
  private showBarInTutorial = false

  /** hodnota na ktorej stlacil */
  public clickedPower = 0

  /** Pocet frameov od zaciatku fazy */
  private framesInPhase = 0

  /** tween na skrytie odrazovej hlasky */
  public startingMessageTween !: gsap.core.Tween

  /** callback na zavolanie po skonceni fazy */
  private callbackEnd: () => unknown

  /** ako dlho bude zobrazena hlaska na odraze */
  private STARTING_MESSAGE_DURATION = 3

  /** Premenna pre kameru */
  private cameraInPostIntroState = false

  /** Tween pre cakanie na startovaci vystrel */
  private waitForStartShotTween!: gsap.core.Tween

  /** zobrazenie bielej ziary */
  private showShine = false

  /**
   * Konstruktor
   */
  public constructor(callbackEnd: () => unknown) {

    this.callbackEnd = callbackEnd

  }

  /**
   * Pripravenie fazy
   */
  public preparePhase(): void {

    this.storeState()
    startPhaseStateManager.disableInputs()
    actionButtonState().isStart = true

  }

  /**
   * Zacatie fazy
   */
  public startPhase(): void {

    if (this.ended) this.reset()

    const shinyTexts = []
    if (modes.isTrainingMode()) shinyTexts.push({ name: 'plus-10-pc',
      active: false })
    shinyTextsState().texts = shinyTexts

    console.warn('starting start phase')
    // Zobrazit meno hraca v UI
    this.showName = !modes.isTutorial()
    this.setCameraForDisciplineIntro()

  }

  /**
   * Nastavenie kamery pre intro
   */
  private setCameraForDisciplineIntro(): void {

    if (gameConfig.cameraConfig.enabled) {

      player.setGameCameraSettings()

    }
    if (modes.isTutorial()) {

      this.afterCameraDisciplineIntro()
      tutorialFlow.init()
      tutorialUIChange.init()
      return

    }
    cameraManager.setState(CameraStates.disciplineIntro)
    cameraManager.playTween(false, this.afterCameraDisciplineIntro)

  }

  /** pomocna metoda pre animacie */
  public getCameraInPostIntroState(): boolean {

    return this.cameraInPostIntroState

  }

  /**
   * Spravenie veci po konci disciplinoveho intra
   */
  public afterCameraDisciplineIntro = (): void => {

    this.cameraInPostIntroState = true
    // Zrusit zobrazenie mena hraca v UI
    this.showName = false
    cameraManager.setState(CameraStates.discipline)

    startPhaseStateManager.postIntroUiState()


    startPhaseStateManager.showStartMessageInUI({ message: 'READY',
      color: 1 })
    console.log('READY')
    audioManager.play(AudioNames.ready)
    this.storeState()
    startPhaseStateManager.enableInputs(true)

    this.waitForStartShotTween = gsap.to({}, {

      onComplete: this.startShot,
      callbackScope: this,
      duration: 3

    })

  }

  /**
   * Startovaci vystrel
   */
  public startShot(): void {

    this.skippable = false
    timeManager.setActive(TimesTypes.game, true)
    uiState().showTimeKeeper = true

    startPhaseStateManager.showStartMessageInUI({ message: 'Start',
      color: 0 })
    opponentsManager.start()
    console.log('START SHOT')
    this.showShine = true
    audioManager.play(AudioNames.startShot)

    // nasledujuce nastavovacky SKONTROLOVAT, ci ich treba
    this.framesInPhase = 0
    this.showBarInTutorial = false
    tutorialState().showBarStart = false
    this.startable = true
    startPhaseStateManager.enableStartInputs()
    startPhaseStateManager.enableInputs()

    gsap.to({}, {
      onComplete: () => {

        this.showShine = false,

        startPhaseState().showShine = this.showShine

      },
      duration: 0.2
    })

  }

  /**
   * Zobrazenie start baru v tutoriali
   */
  public showBarTutorial = (): void => {

    this.showBarInTutorial = true
    tutorialState().showBarStart = true
    this.storeState()

  }

  /**
   * Zacatie hybania sa hraca
   */
  private startMovingPlayer(): void {

    this.startable = false
    console.log(`hrac odstartoval vo frame ${ this.framesInPhase}`)
    this.finishPhase()
    // this.ended = true

  }

  /**
   * Inputy pri starte
   */
  public handleInputs(): void {

    // odstartovanie
    if (this.startable) {

      this.startMovingPlayer()

    }

    // skip intra
    if (this.skippable && this.framesInPhase > 10) {

      console.log('skippped')
      this.skippable = false
      this.skipped = true
      cameraManager.skipTween()
      this.framesInPhase = 0

    }

  }

  /**
   * Update kazdy frame
   */
  public update(): void {

    this.framesInPhase++

    const { active, frames } = gameConfig.autoStart
    if (this.startable && active && this.framesInPhase >= frames) {

      // auto odstartovanie
      console.log('auto start po 12. frameoch')
      this.startMovingPlayer()

    }

    this.storeState()

  }

  /**
   * Ukoncene fazy
   */
  public finishPhase(): void {

    if (!this.ended) {

      console.warn('start phase ended')
      this.showStartMessage()

      this.ended = true
      this.callbackEnd()
      player.start()

      this.storeState()

      startPhaseStateManager.finishPhaseUiState()
      // startPhaseStateManager.disableInputs()

    }

  }

  /**
   * sets finish phase tween
   */
  public setFinishPhaseTween(): void {

    //

  }

  /**
   * UI update
   */
  private storeState(): void {

    startPhaseState().$patch({
      showName: this.showName,
      showPhase: !this.ended,
      showBar: (this.ended || this.showBarInTutorial),
      attempt: corePhasesManager.disciplineActualAttempt,
      showShine: this.showShine
    })

  }

  /**
   * zobrazime startovu spravu
   */
  private showStartMessage(): void {

    // zatial nedavame hlasku po starte
    startPhaseStateManager.disableStartMessageInUI({ message: 'Start',
      color: 0 })
    return

    const message = this.getStartingMessage()
    startPhaseStateManager.showStartMessageInUI(message)

    this.startingMessageTween = gsap.to({}, {
      duration: this.STARTING_MESSAGE_DURATION,
      onComplete: () => {

        startPhaseStateManager.disableStartMessageInUI(message)

      }
    })

  }

  /**
   * ziskame startovu spravu
   */
  private getStartingMessage(): DisplayMessage {

    const message = { message: 'slowStart',
      color: 2 }

    if (this.clickedPower >= 99) {

      message.message = 'perfectStart'
      message.color = 0

    } else if (this.clickedPower >= 90) {

      message.message = 'excellentStart'
      message.color = 0

    } else if (this.clickedPower >= 70) {

      message.message = 'goodStart'
      message.color = 1

    }

    return message

  }

  /**
   * reset fazy
   */
  private reset(): void {

    this.preparePhase()
    inputsState().disabled = true
    this.skippable = true
    this.startable = false
    this.showName = false
    this.skipped = false
    this.ended = false
    this.clickedPower = 0
    this.framesInPhase = 0
    this.storeState()

  }

}
